/* styles.css */

@keyframes colorRotate {
    0% {
      border-top-color: #ff0000; /* Red, top border */
      border-right-color: transparent; /* No color, right border */
      border-bottom-color: transparent; /* No color, bottom border */
      border-left-color: transparent; /* No color, left border */
    }
    25% {
      border-top-color: transparent;
      border-right-color: #00ff00; /* Green, right border */
      border-bottom-color: transparent;
      border-left-color: transparent;
    }
    50% {
      border-top-color: transparent;
      border-right-color: transparent;
      border-bottom-color: #0000ff; /* Blue, bottom border */
      border-left-color: transparent;
    }
    75% {
      border-top-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-left-color: #ffff00; /* Yellow, left border */
    }
    100% {
      border-top-color: #ff0000;
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-left-color: transparent;
    }
  }
  
  .rotating-border {
    animation: colorRotate 5s linear infinite;
    border-width: 4px;
    border-style: solid;
  }
  
  .box{
     position: relative;
     width: 90px;
     height: 50px;
     display:flex;
     justify-content: center;
     align-items: center;
     background-color: rgb(0, 0, 0);
     overflow: hidden;
     border-radius: 20px;
     
     
  }

  .box::before{
    content: "";
    position:absolute;
    width: 37px;
    height: 200%;
    background-color: white;
    box-shadow: 0 0 20px rgb(8,8,8);
    animation: animate 4s linear infinite;
  }

  .box::after{
    content: '';
    position:absolute;
    inset: 5px;
    /* background-color: rgb(4,75,97); */
    background-image: url('/public/sev_Logo.png');
    background-size: cover;
    background-position: center;
    border-radius: 16px;
   
  }

  @keyframes animate{
    0%{
      transform: rotate(0deg);
    }
    100%{
      transform: rotate(360deg);
    }
  }

  li{
    margin-bottom: 10px;
    margin-left: 5px;
    position: relative;
  }

  li::marker {
    content:'\2043'' '; /* Unicode character for a bullet point */
    color: #FFA500; /* Orange color for the bullet point */
    font-size: 1.2em;
    margin-right: 0.5em;
  }
 
  footer {
    background-color: #4d4a4a;
  
  }
  
  